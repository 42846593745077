/* 
File: condition_index_criteria.js
Description: called from ConditionIndexCriteria.vue, provides routines for conditon edit forms calling API's.
*/
import Api from '@/api/condition_index_criteria'

export default {
  state: {
    list: []
  },
  actions: {
    async LOAD_CONDITION_INDEX_CRITERIA_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_condition_index_criteria_all(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_CONDITION_INDEX_CRITERIA_LIST', res.data)
          return 'success';
        } else {
          throw `Error LOAD_CONDITION_INDEX_CRITERIA_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_CONDITION_INDEX_CRITERIA_LIST: ${err}`
      }
    },

    async LOAD_CONDITION_INDEX_CRITERIA_PIVOT({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_condition_index_criteria_pivot(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_CONDITION_INDEX_CRITERIA_LIST', res.data)
          return 'success';
        } else {
          throw `Error LOAD_CONDITION_INDEX_CRITERIA_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_CONDITION_INDEX_CRITERIA_LIST: ${err}`
      }
    },

    async LOAD_CONDITION_INDEX_CRITERIA_BY_ID({ commit }, cond_id) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_condition_index_criteria_by_id(lang, cond_id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_CONDITION_INDEX_CRITERIA_BY_ID'
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async ADD_CONDITION_INDEX_CRITERIA({ commit }, condition) {
      try {
        const res = await Api.add_condition_index_criteria(condition)
        if (res.status === 201) {
          return res.data.cond_id
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async UPD_CONDITION_INDEX_CRITERIA({ commit }, payload) {
      try {
        const { id, condition } = payload
        const res = await Api.upd_condition_index_criteria(id, condition)
        if (res.status === 201) {
          return res.data.cond_id
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async DEL_CONDITION_INDEX_CRITERIA({ commit }, cond_id) {
      try {
        const res = await Api.del_condition_index_criteria(cond_id)
        if (res.status === 200) {
          commit('DEL_CONDITION_INDEX_CRITERIA_FROM_LIST', cond_id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },
  },

  mutations: {
    SET_CONDITION_INDEX_CRITERIA_LIST(state, list) {
      state.list = [...list]
    },
    DEL_CONDITION_INDEX_CRITERIA_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.data_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },
  }
}