/* 
File: treatment_rates.js
Description: called from TreatmentRates.vue and TreatmentRateEditForm.vue, gets rates from DB and passes data back to the caller.
*/
import Api from '@/api/treatments'

export default {
  state: {
    list: [],
  },
  actions: {
    async LOAD_TREATMENT_RATE_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const { status, data } = await Api.load_treatment_rates(lang)
        if (status === 200) {
          commit('SET_TREATMENT_RATE_LIST', data)
        } else {
          throw `Error LOAD_TREATMENT_RATE_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_TREATMENT_RATE_LIST: ${err}`
      }
    },

    async LOAD_TREATMENT_RATE_BY_ID({ }, id) {
      try {
        const { status, data } = await Api.load_treatment_rate_by_id(id)
        if (status == 200) {
          return data
        } else {
          throw 'Error LOAD_TREATMENT_RATE_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.msg}`
      }
    },

    async ADD_TREATMENT_RATE({ commit }, treatmentRate) {
      try {
        const { status } = await Api.add_treatment_rate(treatmentRate)
        if (status == 201) {
          return 'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.msg}` : 'failed'
      }
    },

    async UPD_TREATMENT_RATE({ }, payload) {
      try {
        const { id, treatmentRate } = payload
        const { status } = await Api.upd_treatment_rate(id, treatmentRate)
        if (status == 200) {
          return 'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.msg}` : 'failed'
      }
    },

    // async DEL_RATE({commit}, id) {
    //   try {
    //     commit('SET_USER_OPER', true)
    //     const res = await Api.del_user(id)
    //     if (res.status === 200) {
    //       commit('DEL_USER_FROM_LIST', id)
    //     } else {
    //       throw res.status
    //     }
    //   } catch (err) {
    //     throw err.response ? err.response.data.msg : 'failed'
    //   } finally {
    //     commit('SET_USER_OPER', false)
    //   }
    // }
  },

  mutations: {
    SET_TREATMENT_RATE_LIST(state, list) {
      state.list = [...list]
    },
    // DEL_RATE_FROM_LIST(state, id) {
    //   let ind = state.list.findIndex((row) => row.id === id)
    //   if (ind >= 0) {
    //     state.list.splice(ind, 1)
    //   }
    // },
  },

  getters: {}
}
