/* 
File: import.js
Description: called API for importing data.
*/

import Api from '@/api/import'

export default {
  state: {
    import_status: [],
    import_log: [],
  },

  actions: {
    async GET_ROAD_IMPORT_TEMPLATE({ commit }) {
      try {
        // const { id, theRoad } = payload
        const res = await Api.get_rdb_road_import_template()
        if (res.status === 200) {
          //console.log(res)

          return res  //'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async GET_SECTION_IMPORT_TEMPLATE({ commit }) {
      try {
        const res = await Api.get_rdb_section_import_template()
        if (res.status === 200) {
          return res  //'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async IRI_IMPORT_TEMPLATE({ commit }) {
      try {
        const res = await Api.get_rdb_iri_import_template()
        if (res.status === 200) {
          return res  //'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async RUTTING_IMPORT_TEMPLATE({ commit }) {
      try {
        const res = await Api.get_rdb_iri_import_template()
        if (res.status === 200) {
          return res  //'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async RUN_ROAD_IMPORT({ commit }, import_file) {
      try {
        const { status, data } = await Api.import_rdb_road(import_file)
        if (status === 201) {
          //commit('SET_IMPORT_STATUS', res.data)
          return data  //'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? err.response.data : 'failed'
      }
    },

    async RUN_SECTION_IMPORT({ commit }, import_file) {
      try {
        const { status, data } = await Api.import_rdb_section(import_file)
        if (status === 201) {
          return data  //'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? err.response.data : 'failed'
      }
    },

    async DATA100_IMPORT({ commit }, values) {
      const { import_files, section_id } = values
      try {
        const { status, data } = await Api.import_rdb_data100(import_files, section_id) 
        if (status === 201) {
          return data  //'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? err.response.data : 'failed'
      }
    },

    async IMPORT_LOG_ALL({ commit }, batch_id) {
      try {
        const res = await Api.get_import_log_all(batch_id)
        if (res.status === 200) {
          commit('SET_IMPORT_STATUS', res.data)
          return 'success';
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async IMPORT_LOG_BY_ID({ commit }, log_id) {
      try {
        const res = await Api.get_import_log_by_id(log_id)
        if (res.status === 200) {
          return res.data
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    }
  },

  mutations: {
    SET_IMPORT_STATUS(state, list) {
      state.import_status = [...list]
    },
    SET_IMPORT_LOG(state, list) {
      state.import_log = [...list]
    },
  },
  getters: {}
}