/* 
File: traffic.js
Description: API connections for the async functions for Traffic sites and Traffic installation.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*--------- Traffic sites ---------------------*/
  load_traffic_site_all(lang = 'en') {
    return Api.get(`/rdb/traffic_site/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

  load_traffic_site_by_id(id, lang = 'en') {
    return Api.get(`/rdb/traffic_site/${id}?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

  add_traffic_site(traffic_site) {
    return Api.post(`/rdb/traffic_site`, traffic_site, {
      headers: {
        ...type_json
      }
    })
  },

  upd_traffic_site(id, traffic_site) {
    return Api.put(`/rdb/traffic_site/${id}`, traffic_site, {
      headers: {
        ...type_json
      }
    })
  },

  del_traffic_site(traffic_site_id) {
    return Api.delete(`/rdb/traffic_site/${traffic_site_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /*--------- Traffic installation ---------------------*/

  load_traffic_installation_all() {
    return Api.get(`/rdb/traffic_installation/all`, {
      headers: {
        ...type_json
      }
    })
  },

  load_traffic_installation_by_id(id) {
    return Api.get(`/rdb/traffic_installation/${id}`, {
      headers: {
        ...type_json
      }
    })
  },

  add_traffic_installation(traffic) {
    return Api.post(`/rdb/traffic_installation`, traffic, {
      headers: {
        ...type_json
      }
    })
  },

  upd_traffic_installation(id, traffic) {
    return Api.put(`/rdb/traffic_installation/${id}`, traffic, {
      headers: {
        ...type_json
      }
    })
  },
}