/* 
File: inventory_events.js
Description: called from InventoryEvents.vue and InventoryEvenmtEditForm.vue, 
gets Inventory event data from DB and passes as a list back to the caller.
*/
import Api from '@/api/inventory'

export default {
  state: {
    list: []
  },

  actions: {
    async LOAD_INVENTORY_EVENTS_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]

      try {
        const res = await Api.load_inventory_events_list(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_INVENTORY_EVENTS_LIST', res.data)
          return 'success';
        } else {
          throw `Error LOAD_INVENTORY_EVENTS_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_INVENTORY_EVENTS_LIST: ${err}`
      }
    },

    async LOAD_INVENTORY_EVENT_BY_ID({ commit }, inventory_event_id) {
      try {
        const res = await Api.load_rdb_inventory_event_by_id(inventory_event_id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_INVENTORY_EVENT_BY_ID'
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async ADD_INVENTORY_EVENT({ commit }, newInventoryEvent) {
      try {
        const res = await Api.add_rdb_inventory_event(newInventoryEvent)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async UPD_INVENTORY_EVENT({ commit }, payload) {
      try {
        const { id, invEvent } = payload
        const res = await Api.upd_rdb_inventory_event(id, invEvent)
        if (res.status === 201) {    //200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },
  },

  mutations: {
    SET_INVENTORY_EVENTS_LIST(state, list) {
      state.list = [...list]
    }
  },

  getters: {}
}