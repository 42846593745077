/* 
File: sections.js
Description: called from Sections.vue and RoadsList.vue, gets section data for given road 
             from DB and passes as a list back to the caller.
*/
import Api from '@/api/sections';

export default {
  state: {
    list: [],
  },
  actions: {
    async LOAD_SECTIONS_LIST({ commit }, values) {
      const { region_id, road_id, deu_id, for_map } = values
      if (!region_id && !road_id && !for_map) {
        commit('SET_SECTIONS_LIST', [])
        return 'success'
      }

      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_sections_all(lang, region_id, road_id, deu_id)
        const { status } = res
        if (status === 200) {
          commit('SET_SECTIONS_LIST', res.data)
          return 'success'
        } else {
          throw `Error LOAD_SECTIONS_LIST: ${status}`
        }
      } catch (err) {
        throw `Load section list: ${err}`
      }
    },

    async LOAD_SECTION_BY_ID({ commit }, sid) {
      const lang = global.localStorage["mps-locale"]
      try {
        const { status, data } = await Api.load_section_by_id(sid, lang)
        if (status === 200) {
          return data
        } else {
          throw 'Error LOAD_SECTION_BY_ID'
        }
      } catch (err) {
        console.error(err.response.data.msg)
        return null
        //throw `Load section by ID: ${err.response.data.msg}`
      }
    },

    async GET_REGION_ROAD_FROM_SECTION({ commit }, id) {
      try {
        const { status, data } = await Api.get_region_road_id_from_section(id)
        if (status === 200) {
          return data
        } else {
          throw 'Error GET_REGION_ROAD_FROM_SECTION'
        }
      } catch (err) {
        console.error(err.response.data.msg)
        return null
        //throw `Load section by ID: ${err.response.data.msg}`
      }
    },

    async ADD_SECTION({ commit }, section) {
      try {
        const res = await Api.add_section(section)
        if (res.status === 201) {
          return res.data.section_id
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },
    async UPD_SECTION({ commit }, payload) {
      try {
        const { id, section } = payload
        const res = await Api.upd_section(id, section)
        if (res.status === 200) {
          return res.data.section_id
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async DEL_SECTION({ commit }, section_id) {
      try {
        const res = await Api.del_section(section_id)
        if (res.status === 200) {
          commit('DEL_SECTION_FROM_LIST', section_id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    }
  },

  mutations: {
    SET_SECTIONS_LIST(state, list) {
      state.list = [...list];
    },
    DEL_SECTION_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.section_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },
  },

  getters: {
    roadsList: (state) => {
      const uniqueIds = new Set();
      return state.list.reduce((acc, item) => {
        if (!uniqueIds.has(item.road_id)) {
          uniqueIds.add(item.road_id);
          acc.push({ id: item.road_id, name: item.road, deu_id: item.deu_id });
        }
        return acc;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
    },

    roadsListFiltered: (state, getters) => (deu_id) => {
      const res = getters.roadsList.filter(item =>
        (!deu_id || item.deu_id === deu_id)  // Проверка на ДЭУ (если передано)
      )
      res.unshift({ id: 0, name: '', deu_id: null })   // Add null value to the beginnig
      return res
    },

    sectionsFiltered: (state) => (deuLabel, region_id, deu_id, road_id) => {
      return state.list.filter(item =>
        (!region_id || item.region_id === region_id) &&  // Проверка на регион (если передан)
        (!deu_id || item.deu_id === deu_id) &&  // Проверка на ДЭУ (если передано)
        (!road_id || item.road_id === road_id)           // Проверка на дорогу (если передана)
      )
      .map(item => ({...item, deu: `${deuLabel}-${item.deu}`}))
    },
  }
};