/* 
File: inventorytypes.js
Description: called from InventoryTypes.vue and InventoryTypeEditForm.vue, 
gets Inventory type data from DB and passes as a list back to the caller.
*/
import Api from '@/api/inventory'

export default {
  state: {
    list: []
  },

  actions: {
    async LOAD_INVENTORY_TYPES_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]

      try {
        const res = await Api.load_inventory_types_list(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_INVENTORY_TYPES_LIST', res.data)
          return 'success';
        } else {
          throw `Error LOAD_INVENTORY_TYPES_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_INVENTORY_TYPES_LIST: ${err}`
      }
    },

    async LOAD_INVENTORY_TYPE_BY_ID({ commit }, inventory_type_id) {
      try {
        const res = await Api.load_rdb_inventory_type_by_id(inventory_type_id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_INVENTORY_TYPE_BY_ID'
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async ADD_INVENTORY_TYPE({ commit }, newInventoryType) {
      try {
        const res = await Api.add_rdb_inventory_type(newInventoryType)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async UPD_INVENTORY_TYPE({ commit }, payload) {
      try {
        const { id, invType } = payload
        const res = await Api.upd_rdb_inventory_type(id, invType)
        if (res.status === 201) {    //200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },
  },

  mutations: {
    SET_INVENTORY_TYPES_LIST(state, list) {
      state.list = [...list]
    }
  },

  getters: {}
}