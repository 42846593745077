/* 
File: treatment_types.js
Description: called from TreatmentTypes.vue and TreatmentTypeEditForm.vue, 
gets treatment data from DB and passes data back to the caller.
*/
import Api from '@/api/treatments'

// Move to the dropdowns
const thresholdVariablesList = [
  { key: "rutting", description_en: 'Rutting', description_ru: 'Колейность', description_kg: 'Колейность' },
  { key: "iri", description_en: 'IRI', description_ru: 'Ровность', description_kg: 'Ровность' },
  { key: "aadt", description_en: 'AADT', description_ru: 'Интенсивность', description_kg: 'Интенсивность' },
  { key: "cracking", description_en: 'Cracking', description_ru: 'Трещины', description_kg: 'Трещины' },
  { key: "potholes", description_en: 'Potholes', description_ru: 'Ямы', description_kg: 'Ямы' },
]

export default {
  state: {
    list: [],
    variablesList: [],
  },
  actions: {
    async LOAD_TREATMENT_TYPES_LIST({ commit }, show_all = false) {
      const lang = global.localStorage["mps-locale"]

      try {
        const res = await Api.load_treatment_types(lang)
        const { status } = res
        if (status === 200) {
          if (show_all) res.data.splice(0, 0, { treatment_type_id: 0, key: '', treatment_type_description: '' })
          commit('SET_TREATMENT_TYPES_LIST', res.data)
        } else {
          throw `Error LOAD_TREATMENT_TYPES_LIST: ${status}`
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async LOAD_TREATMENT_TYPE_BY_ID({ commit }, id) {
      try {
        const res = await Api.load_treatment_type_by_id(id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_TREATMENT_TYPE_BY_ID'
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async ADD_TREATMENT_TYPE({ commit }, treatment_type) {
      try {
        const res = await Api.add_treatment_type(treatment_type)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async UPD_TREATMENT_TYPE({ }, payload) {
      try {
        const { id, treatment_type } = payload
        const res = await Api.upd_treatment_type(id, treatment_type)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async GET_THRESHOLD_VARIABLES_DROPDOWN({ commit }, show_all = false) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = []
        thresholdVariablesList.forEach((element) => {
          res.push({ key: element.key, description: element[`description_${lang}`] })
        })
        if (show_all) res.splice(0, 0, { key: '', description: '' })
        commit('SET_VARIABLES_LIST', res)
        return 'success'
      } catch (err) {
        throw `GET_THRESHOLD_VARIABLES_DROPDOWN: ${err}`
      }
    },
  },

  mutations: {
    SET_TREATMENT_TYPES_LIST(state, list) {
      state.list = [...list]
    },
    SET_VARIABLES_LIST(state, list) {
      state.variablesList = [...list]
    },
  },
  getters: {}
}
