/* 
File: section_geometry.js
Description: info about Section geometry shown in the Section.vue.
*/
import Api from '@/api/sections';

export default {
  state: {
    list: [],
  },
  actions: {
    async LOAD_SECTION_GEOMETRY_ALL({ commit }, section_id) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_rdb_section_geometry_all(lang, section_id)
        const { status } = res
        if (status === 200) {
          commit('SET_SECTION_GEOMETRY', res.data)
          return 'success';
        } else {
          throw `Error LOAD_SECTION_GEOMETRY_ALL: ${status}`
        }
      } catch (err) {
        throw `Load section geometry list: ${err}`
      }
    },

    async LOAD_SECTION_GEOMETRY_BY_ID({ commit }, geometry_id) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_rdb_section_geometry_by_id(lang, geometry_id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_SECTION_GEOMETRY_BY_ID'
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async ADD_SECTION_GEOMETRY({ commit }, geometry) {
      try {
        const res = await Api.add_rdb_section_geometry(geometry)
        if (res.status === 201) {
          return res.data.geometry_id
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async UPD_SECTION_GEOMETRY({ commit }, payload) {
      try {
        const { id, geometry } = payload
        const res = await Api.upd_rdb_section_geometry(id, geometry)
        if (res.status === 200) {
          return res.data.geometry_id
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    },

    async DEL_SECTION_GEOMETRY({ commit }, geometry_id) {
      try {
        const res = await Api.del_rdb_section_geometry(geometry_id)
        if (res.status === 200) {
          commit('DEL_SECTION_GEOMETRY_FROM_LIST', geometry_id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      }
    }
  },

  mutations: {
    SET_SECTION_GEOMETRY(state, list) {
      state.list = [...list];
    },
    DEL_SECTION_GEOMETRY_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.geometry_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },
  },

  getters: {
    lastGeometry: (state) => {
      const len = state.list.length
      return len > 0 ? state.list[len - 1] : {}
    }
  }
}