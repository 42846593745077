import Api from '@/api/users_groups'
const acc_level = [
  { id: 0, name_en: 'DDH', name_ru: 'ДДХ' },
  { id: 1, name_en: 'RD/UAD', name_ru: 'РО/УАД' },
  { id: 2, name_en: 'DEU', name_ru: 'ДЭУ' }
]

export default {
  state: {
    list: [],
    isGroupsOper: false
  },
  actions: {
    async LOAD_GROUP_LIST({ commit }) {
      try {
        commit('SET_GROUP_OPER', true)
        const res = await Api.load_group_list()
        const { status } = res
        if (status === 200) {
          commit('SET_GROUP_LIST', res.data)
        } else {
          throw 'Error LOAD_GROUPS_LIST'
        }
      } catch (err) {
        throw `Error LOAD_GROUPS_LIST: ${err}`
      } finally {
        commit('SET_GROUP_OPER', false)
      }
    },
    async LOAD_REGION_LIST({ }) {
      try {
        const res = await Api.load_region_list()
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_REGION_LIST'
        }
      } catch (err) {
        throw `Error LOAD_REGION_LIST: ${err}`
      } finally {
      }
    },
    async LOAD_ACC_LEVEL_LIST({ }) {
      try {
        return acc_level
      } catch (err) {
        throw `Error LOAD_ACC_LEVEL_LIST: ${err}`
      } finally {
      }
    },
    async LOAD_GROUP_BY_ID({ commit }, id) {
      try {
        commit('SET_GROUP_OPER', true)
        const res = await Api.load_group_by_id(id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_GROUP_BY_ID'
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'failed'
      } finally {
        commit('SET_GROUP_OPER', false)
      }
    },
    async ADD_GROUP({ commit }, group) {
      try {
        commit('SET_GROUP_OPER', true)
        const res = await Api.add_group(group)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'filed'
      } finally {
        commit('SET_GROUP_OPER', false)
      }
    },
    async UPD_GROUP({ commit }, payload) {
      try {
        const { id, group } = payload
        commit('SET_GROUP_OPER', true)
        const res = await Api.upd_group(id, group)
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'filed'
      } finally {
        commit('SET_GROUP_OPER', false)
      }
    },
    async DEL_GROUP({ commit }, id) {
      try {
        commit('SET_GROUP_OPER', true)
        const res = await Api.del_group(id)
        if (res.status === 200) {
          commit('DEL_GROUP_FROM_LIST', id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? err.response.data.msg : 'filed'
      } finally {
        commit('SET_GROUP_OPER', false)
      }
    }
  },
  mutations: {
    SET_GROUP_LIST(state, list) {
      state.list = [...list]
    },
    DEL_GROUP_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.id === id)
      if (ind >= 0) {
        state.list.splice(ind, 1)
      }
    },
    SET_GROUP_OPER(state, val) {
      state.isGroupOper = val
    }
  },

  getters: {
    groupById: (state) => (id) => {
      const res = (!id) ? '' : state.list.find(item => item.id === id)
      const lang = global.localStorage["mps-locale"]
      return (!res) ? '' : res[`name_${lang}`]
    }
  }
}
