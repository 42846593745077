/* 
File: refdata.js
Description: api's for reference data - carriageways, pavement types, etc.
*/
import Api from '@/api/reference_data'

const LRPs = [
  { id: 1, description: 'Intersection', },
  { id: 2, description: 'Railway crossing', },
  { id: 3, description: 'Bridge', },
  { id: 4, description: 'Culvert', },
  { id: 5, description: 'Kilometre post', },
  { id: 6, description: 'Start / End', },
  { id: 7, description: 'Border' }
]

export default {
  state: {
    pavement_types: [],
    surface_types: [],
    terrain_types: [],
    LandUseTypes: [],
    lprs: [],
    workAreas: []
  },

  actions: {
    async LOAD_RDB_LOOKUP({ }, lookup_type) {
      const lang = global.localStorage["mps-locale"]
      try {
        const { status, data } = await Api.load_rdb_lookup(lang, lookup_type)
        if (status === 200) {
          const res = data.map(item => ({
            id: item.lookup_key,
            description: item.description
          }))
          return res
        } else {
          throw `LOAD_RDB_LOOKUP status: ${status}`
        }
      } catch (err) {
        throw `LOAD_RDB_LOOKUP: ${err}`
      }
    },

    async LOAD_PAVEMENT_TYPES({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'pavement_type')
      commit('SET_PAVEMENT_TYPES', res)
    },

    async LOAD_SURFACE_TYPES({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'surface_type')
      commit('SET_SURFACE_TYPES', res)
    },

    async LOAD_TERRAIN_TYPES({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'terrain_type')
      commit('SET_TERRAIN_TYPES', res)
    },

    async LOAD_LAND_USE({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'land_use')
      commit('SET_LAND_USE', res)
    },

    async LOAD_LRPS({ commit, dispatch }) {
      //const res = await dispatch('LOAD_RDB_LOOKUP', 'lrp')
      //commit('SET_LRPS', res)
      commit('SET_LRPS', LRPs)
    },

    async LOAD_WORK_AREAS({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'work_area')
      commit('SET_WORK_AREAS', res)
    },
},

  mutations: {
    SET_PAVEMENT_TYPES(state, list) {
      state.pavement_types = [...list]
    },
    SET_SURFACE_TYPES(state, list) {
      state.surface_types = [...list]
    },
    SET_TERRAIN_TYPES(state, list) {
      state.terrain_types = [...list]
    },
    SET_LAND_USE(state, list) {
      state.LandUseTypes = [...list]
    },
    SET_LRPS(state, list) {
      state.lrps = [...list]
    },
    SET_WORK_AREAS(state, list) {
      state.workAreas = [...list]
    },
  },

  getters: {
  }
}
